import React, {useEffect, useState} from "react";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {Button, Card, CardBody, CardFooter, CardHeader, Typography} from "@material-tailwind/react";
import {useNavigate} from "react-router-dom";

export default function PatientTable({patients, columns, handleOpenAddPatientForm}) {
    const [rowSelection, setRowSelection] = useState({});
    const [selectedPatient, setSelectedPatient] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        setSelectedPatient(patients[Object.keys(rowSelection)[0]]);
    }, [patients, rowSelection]);

    const table = useMaterialReactTable({
        columns,
        data: Array.isArray(patients) ? patients : [],
        enableRowSelection: true,
        enableMultiRowSelection: false,
        onRowSelectionChange: setRowSelection,
        state: {rowSelection},
    });

    const seeDetailsEnabled = () => {
        return selectedPatient !== undefined;
    };

    const onClickAddPatient = async () => {
        await handleOpenAddPatientForm();
    };

    const handleOnClickSeePatientDetails = async () => {
        navigate(`${selectedPatient.patientID}`);
    };

    return (
        <>
            <section className="pt-4 pb-4">
                <Card className="h-full w-full">
                    <CardHeader
                        floated={false}
                        shadow={false}
                        className="rounded-none flex flex-wrap gap-4 justify-between mb-4 p-2">
                        <div>
                            <Typography
                                variant="h6"
                                color="blue-gray"></Typography>
                            <Typography
                                variant="small"
                                className="text-gray-600 font-normal"></Typography>
                        </div>
                        {handleOpenAddPatientForm && (
                            <div className="flex flex-wrap items-center w-full shrink-0 gap-4 md:w-max">
                                <Button
                                    color="blue"
                                    className="md:max-w-fit w-full"
                                    onClick={() => onClickAddPatient()}>
                                    add a new patient
                                </Button>
                            </div>
                        )}
                    </CardHeader>
                    <CardBody className="overflow-scroll !p-0">
                        <MaterialReactTable table={table} />
                    </CardBody>
                    <CardFooter className="rounded-none flex flex-wrap gap-4 justify-between mb-4 ps-8">
                        <div>
                            <Typography
                                variant="para"
                                color="blue-gray">
                                {selectedPatient &&
                                    `Selected Patient: ${selectedPatient.firstName} ${selectedPatient.lastName}`}
                            </Typography>
                        </div>
                        <div className="flex flex-wrap items-center w-full shrink-0 gap-4 md:w-max">
                            <Button
                                disabled={!seeDetailsEnabled()}
                                seeDetailsEnabled
                                className="md:max-w-fit w-full"
                                onClick={() => handleOnClickSeePatientDetails()}>
                                See Details
                            </Button>
                        </div>
                    </CardFooter>
                </Card>
            </section>
        </>
    );
}
