import React, {useEffect, useMemo, useState} from "react";
import {getAllPatients} from "../../../services/patientService";
import {useAuth} from "../../../providers/AuthProvider";
import PatientTable from "../../../components/PatientTable";
import CreatePatientModal from "../../../components/CreatePatientModal";
import {Typography} from "@material-tailwind/react";

export default function PatientsView() {
    const {user} = useAuth();
    const [patients, setPatients] = useState([]);
    const [openCreatePatientForm, setOpenCreatePatientForm] = React.useState(false);

    /**
     * Retrieves the list of patients when the component mounts and the modal is not visible.
     */
    useEffect(() => {
        if (openCreatePatientForm === false) {
            const data = {organisationID: user.organisationID};
            getPatients(data)
                .then((response) => {
                    setPatients(response);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [openCreatePatientForm, user.organisationID]);

    /**
     * Handles the opening of the add patient form.
     * @async
     * @returns {Promise<void>}
     */
    const handleOpenAddPatientForm = async () => {
        setOpenCreatePatientForm((cur) => !cur);
    };

    /**
     * Retrieves the list of patients.
     *
     * @param {Object} data - The data needed to retrieve the patients.
     * @returns {Promise<Array>} A promise that resolves to an array of patients.
     * @throws {Error} If there was an error retrieving the patients.
     */
    const getPatients = (data) => {
        return new Promise((resolve, reject) => {
            getAllPatients(data)
                .then((response) => {
                    if (response.success === false) {
                        reject(response.error);
                        return;
                    }
                    resolve(response.response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    const patientsColumns = useMemo(
        () => [
            {
                accessorKey: "firstName",
                header: "First Name",
            },
            {
                accessorKey: "lastName",
                header: "Last Name",
            },
            {
                accessorKey: "carerName",
                header: "Carer",
            },
        ],
        [],
    );

    return (
        <>
            <div className="p-4 space-y-4">
                <Typography
                    variant="h1"
                    color="blue-gray">
                    Patients
                </Typography>
                <PatientTable
                    patients={patients}
                    columns={patientsColumns}
                    handleOpenAddPatientForm={handleOpenAddPatientForm}
                />
                <CreatePatientModal
                    open={openCreatePatientForm}
                    handleOpen={handleOpenAddPatientForm}
                />
            </div>
        </>
    );
}
