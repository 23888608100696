import {httpsCallable} from "firebase/functions";
import {functions} from "../config/firebase.config";

export const makePatientCarerAssignment = async (data) => {
    try {
        await assignPatientToCarer(data);
        await assignCarerToPatient(data);
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const assignPatientToCarer = async (data) => {
    try {
        // Call the Firebase cloud function and await the response
        console.info("Assigning patient to carer", data);
        const callable = httpsCallable(functions, "assignCarer");
        const response = await callable(data);
        return response.data;
    } catch (error) {
        console.error(error);
        // Handle failures by returning a custom error
        throw new Error("Failed to assign patient to carer");
    }
};

const assignCarerToPatient = async (data) => {
    try {
        // Call the Firebase cloud function and await the response
        console.info("Assigning carer to patient", data);
        const callable = httpsCallable(functions, "assignPatient");
        const response = await callable(data);
        return response.data;
    } catch (error) {
        console.error(error);
        // Handle failures by returning a custom error
        throw new Error("Failed to assign carer to patient");
    }
};
