import {Button, Typography} from "@material-tailwind/react";

export default function NewFeaturesWidget() {
    return (
        <section className="">
            <div className="rounded-xl bg-blue-gray-200 bg-cover bg-center p-10">
                <Typography
                    variant="small"
                    color="white"
                    className="font-bold mb-2">
                    New Features
                </Typography>
                <Typography
                    variant="h3"
                    color="white">
                    Updated AI model released
                </Typography>
                <Typography
                    color="white"
                    className="mt-2 mb-6 !text-base">
                    Learn how you can tailor your new patients non-clinical care plans with our updated AI model.
                </Typography>
                <Button
                    color="gray"
                    className="flex-shrink-0">
                    try it now
                </Button>
            </div>
        </section>
    );
}
