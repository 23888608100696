import {onAuthStateChanged, signInWithEmailAndPassword, signOut} from "firebase/auth";
import {createContext, useContext, useEffect, useState} from "react";
import {auth, functions} from "../config/firebase.config";
import {httpsCallable} from "firebase/functions";

export const AuthContext = createContext(null);

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({children}) {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const createOrganisation = async (data) => {
        try {
            setLoading(true);
            console.log(data);
            const callable = httpsCallable(functions, "createOrganisation");
            const response = await callable(data);
            return response.data;
        } catch (error) {
            console.error(error);
            // Handle failures by returning a custom error
            throw new Error("Failed to create organisational user");
        }
    };

    const createOrganisationalCarer = async (data) => {
        try {
            setLoading(true);
            const callable = httpsCallable(functions, "createOrganisationalCarer");
            const response = await callable(data);
            return response.data;
        } catch (error) {
            console.error(error);
            // Handle failures by returning a custom error
            throw new Error("Failed to create organisational user");
        }
    };

    const getCoordinatedLivingUser = async (userRecordID) => {
        const data = {userID: userRecordID};
        const callable = httpsCallable(functions, "getUser");
        const response = await callable(data);
        return response.data;
    };

    const loginUser = async (email, password) => {
        setLoading(true);
        return signInWithEmailAndPassword(auth, email, password);
    };

    const logOut = async () => {
        setLoading(true);
        return await signOut(auth);
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (!currentUser) {
                setUser(null);
                setLoading(false);
                return;
            }

            getCoordinatedLivingUser(currentUser.uid)
                .then((coordinatedLivingUser) => {
                    console.info("User record fetched", coordinatedLivingUser);
                    setUser(coordinatedLivingUser.response);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const authValue = {
        createOrganisation,
        createOrganisationalCarer,
        user,
        loginUser,
        logOut,
        loading,
    };

    return <AuthContext.Provider value={authValue}>{!loading && children}</AuthContext.Provider>;
}
