import {Button, Option, Select} from "@material-tailwind/react";
import React, {useEffect, useState} from "react";
import {useAuth} from "../providers/AuthProvider";
import {getAllUnassignedPatients} from "../services/patientService";
import {makePatientCarerAssignment} from "../services/assignmentService";
import {getCarerPatients} from "../services/carerService";

export default function AssignCarer({carer, setCarersPatients}) {
    const {user} = useAuth();
    const [patients, setPatients] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState();

    useEffect(() => {
        const data = {organisationID: user.organisationID};
        getAllUnassignedPatients(data)
            .then((response) => {
                console.info(response);
                setPatients(response);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [user.organisationID]);

    const onClickAssign = () => {
        const patient = patients.find((patient) => patient.patientID === selectedPatient);

        const data = {
            organisationID: user.organisationID,
            patientID: selectedPatient,
            carerID: carer.userID,
            carerName: `${carer.firstName} ${carer.lastName}`,
            patientName: `${patient.firstName} ${patient.lastName}`,
        };
        console.info(data);

        makePatientCarerAssignment(data)
            .then((response) => {
                setSelectedPatient(null);
                setPatients([]);
                return getAllUnassignedPatients({organisationID: user.organisationID});
            })
            .then((response) => {
                setPatients(response);
                return getCarerPatients({organisationID: user.organisationID, carerID: carer.userID});
            })
            .then((response) => {
                setCarersPatients(response.response);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <>
            {patients && patients.length > 0 && (
                <section className="container mx-auto">
                    <div className="flex flex-col mt-4">
                        <div className="mb-6 flex flex-col items-end gap-4 md:flex-row">
                            <div className="w-full">
                                <Select
                                    label="Select a patient"
                                    value={selectedPatient}
                                    onChange={(val) => setSelectedPatient(val)}>
                                    {patients.map((patient) => (
                                        <Option
                                            value={
                                                patient.patientID
                                            }>{`${patient.firstName} ${patient.lastName}`}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="w-full">
                                <Button
                                    fullWidth
                                    onClick={() => onClickAssign()}>
                                    Assign
                                </Button>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}
