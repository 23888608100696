import {Typography, Input, Button} from "@material-tailwind/react";
import holdingHandsImage from "../../assets/img/cover-1.jpg";
import {useState} from "react";
import {useAuth} from "../../providers/AuthProvider";
import validator from "validator";
import {useNavigate} from "react-router-dom";

export default function SignUpView() {
    const {createOrganisation, createOrganisationalCarer} = useAuth();
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [organisationalID, setOrganisationalID] = useState("");

    const onClickSignUp = async () => {
        if (password && confirmPassword && confirmPassword !== password) {
            console.error("Passwords do not match. Please try again");
            return;
        }

        if (validator.isEmail(email) === false) {
            console.error("Invalid email. Please try again");
            return;
        }

        if (organisationalID && organisationalID.length > 0) {
            const data = {};
            data["organisationID"] = organisationalID;
            data["user"] = {email: email, password: password, role: "organisationalCarer"};
            await createOrganisationalCarer(data);
            navigate("/member/dashboard", {replace: true});
        } else {
            const data = {};
            data["user"] = {email: email, password: password, role: "organisation"};
            await createOrganisation(data);
            navigate("/member/dashboard", {replace: true});
        }
    };

    return (
        <section className="grid h-screen items-center lg:grid-cols-2">
            <div className="my-auto p-8 text-center sm:p-10 md:p-20 xl:px-32 xl:py-24">
                <Typography
                    variant="h3"
                    color="blue-gray"
                    className="mb-2">
                    Sign Up
                </Typography>
                <Typography
                    color="gray"
                    className="mb-12">
                    Enter your email and password to sign in
                </Typography>

                <form
                    action="#"
                    className="mx-auto max-w-[24rem] text-left">
                    <div className="mb-4">
                        <Input
                            color="gray"
                            size="lg"
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <Input
                            color="gray"
                            size="lg"
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <Input
                            color="gray"
                            size="lg"
                            label="Confirm Password"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <Input
                            color="gray"
                            size="lg"
                            label="Enter organisational ID (optional)"
                            type="password"
                            value={organisationalID}
                            onChange={(e) => setOrganisationalID(e.target.value)}
                        />
                    </div>

                    <Button
                        onClick={() => onClickSignUp()}
                        color="gray"
                        size="lg"
                        className="mt-6"
                        fullWidth>
                        Sign Up
                    </Button>
                    <Typography
                        color="gray"
                        className="m-8">
                        Already have an account? Click here to{" "}
                        <a
                            href="/login"
                            className="text-blue-500">
                            Log In
                        </a>
                    </Typography>
                </form>
            </div>
            <img
                src={holdingHandsImage}
                alt="Holding hands"
                className="hidden h-screen w-full object-cover lg:block"
            />
        </section>
    );
}
