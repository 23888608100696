import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useAuth} from "../../../providers/AuthProvider";
import {Typography} from "@material-tailwind/react";
import {getPatientActivities} from "../../../services/activitiesService";
import ActivitiesTable from "../../../components/ActivitiesTable";
import AddActivityModal from "../../../components/AddActivityModal";

export default function PatientActivitiesView() {
    const {user} = useAuth();
    const location = useLocation();
    const [activities, setActivities] = useState([]);
    const [openCreateForm, setOpenCreateForm] = React.useState(false);

    const handleOpenAddForm = async () => {
        setOpenCreateForm((cur) => !cur);
    };

    useEffect(() => {
        if (location.state.patient) {
            const data = {organisationID: user.organisationID, patientID: location.state.patient.patientID};

            getPatientActivities(data)
                .then((response) => {
                    setActivities(response);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [location.state.patient, user.organisationID]);

    return (
        <>
            <div className="p-4 space-y-4">
                {location.state.patient && (
                    <Typography
                        variant="h1"
                        color="blue-gray">
                        {location.state.patient.firstName} {location.state.patient.lastName}
                    </Typography>
                )}

                <ActivitiesTable
                    patient={location.state.patient}
                    activities={activities ? activities : []}
                    handleOpenAddForm={handleOpenAddForm}
                />

                {location.state.patient && (
                    <AddActivityModal
                        open={openCreateForm}
                        handleOpen={handleOpenAddForm}
                        patient={location.state.patient}
                    />
                )}
            </div>
        </>
    );
}
