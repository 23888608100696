import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import PatientBasicDetail from "../../../components/PatientBasicDetail";
import {useAuth} from "../../../providers/AuthProvider";
import {getPatient} from "../../../services/patientService";
import {getAllCarers} from "../../../services/carerService";
import InformationCard from "../../../components/InformationCard";
import {Typography} from "@material-tailwind/react";

export default function PatientView() {
    const INFO_POINTS = [
        {
            subHeading: "Patient Interests",
            title: "View All ",
            cardButtonActionTitle: "View all",
            cardButtonParam: "patientInterests",
        },
        {
            subHeading: "Patient Goals",
            title: "View All ",
            cardButtonActionTitle: "View all",
            cardButtonParam: "patientGoals",
        },
        {
            subHeading: "Patient Interventions",
            title: "View All ",
            cardButtonActionTitle: "View all",
            cardButtonParam: "patientInterventions",
        },
        {
            subHeading: "Patient Content",
            title: "Generated Content",
            cardButtonActionTitle: "View all",
            cardButtonParam: "patientGeneratedContent",
        },
    ];

    const ACTIONS = [
        {
            subHeading: "Generation",
            title: "Activities",
            cardButtonActionTitle: "Generate",
            cardButtonParam: "generateActivities",
        },
        {
            subHeading: "Generation",
            title: "Articles",
            cardButtonActionTitle: "Generate",
            cardButtonParam: "generateArticles",
        },
        {
            subHeading: "Generation",
            title: "Quizzes",
            cardButtonActionTitle: "Generate",
            cardButtonParam: "generateQuizzes",
        },
    ];

    const params = useParams();
    const {user} = useAuth();
    const [patient, setPatient] = useState();
    const [carers, setCarers] = useState();
    const navigate = useNavigate();
    const [outputs] = useState([]);

    useEffect(() => {
        getPatient({patientID: params.patientID, organisationID: user.organisationID})
            .then((response) => {
                console.info(response);
                setPatient(response.response);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [params.patientID, user.organisationID]);

    useEffect(() => {
        getAllCarers({organisationID: user.organisationID})
            .then((response) => {
                console.info(response);
                setCarers(response.response);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [user.organisationID]);

    /**
     * Navigates to the specified path with the given state object.
     *
     * @param {string} path - The path to navigate to.
     * @param {object} stateObj - The state object to pass to the navigation.
     */
    const navigateTo = (path, stateObj) => {
        navigate(path, {
            state: stateObj,
        });
    };

    /**
     * Handles the action triggered by a card button.
     * @param {string} action - The action to be performed.
     */
    const handleCardButtonAction = (action) => {
        console.info("handleCardButtonAction", action);
        switch (action) {
            case "assignNewCarer":
                navigateTo("carer", {patient: patient, carers: carers});
                break;
            case "patientInterests":
                navigateTo("interests", {patient: patient});
                break;
            case "patientGoals":
                navigateTo("goals", {patient: patient});
                break;
            case "patientInterventions":
                navigateTo("interventions", {patient: patient});
                break;
            case "patientGeneratedContent":
                navigateTo("generated-content", {patient: patient});
                break;
            case "generateActivities":
                navigateTo("activities", {patient: patient});
                break;
            case "generateArticles":
                navigateTo("articles", {patient: patient});
                break;
            case "generateQuizzes":
                navigateTo("quizzes", {patient: patient});
                break;
            default:
                break;
        }
    };

    return (
        <>
            {patient && carers && (
                <>
                    <div className="p-4 space-y-4">
                        <Typography
                            variant="h1"
                            color="blue-gray">
                            {patient.firstName} {patient.lastName}
                        </Typography>
                        <PatientBasicDetail patient={patient} />
                        {INFO_POINTS && (
                            <>
                                <Typography
                                    variant="h5"
                                    color="blue-gray">
                                    Information specific to {patient.firstName} {patient.lastName}
                                </Typography>

                                <div className="grid gap-6 md:grid-cols-2 xl:grid-cols-4">
                                    {INFO_POINTS.map(
                                        ({icon, subHeading, title, cardButtonActionTitle, cardButtonParam}) => (
                                            <InformationCard
                                                icon={icon}
                                                subHeading={subHeading}
                                                title={title}
                                                cardButtonActionTitle={cardButtonActionTitle}
                                                cardButtonAction={handleCardButtonAction}
                                                cardButtonParam={cardButtonParam}
                                            />
                                        ),
                                    )}
                                </div>
                            </>
                        )}
                        {ACTIONS && (
                            <>
                                <Typography
                                    variant="h5"
                                    color="blue-gray">
                                    Actions specific to {patient.firstName} {patient.lastName}
                                </Typography>

                                <div className="grid gap-6 md:grid-cols-2 xl:grid-cols-4">
                                    {ACTIONS.map(
                                        ({icon, subHeading, title, cardButtonActionTitle, cardButtonParam}) => (
                                            <InformationCard
                                                icon={icon}
                                                subHeading={subHeading}
                                                title={title}
                                                cardButtonActionTitle={cardButtonActionTitle}
                                                cardButtonAction={handleCardButtonAction}
                                                cardButtonParam={cardButtonParam}
                                            />
                                        ),
                                    )}
                                    {ACTIONS.length === 0 && (
                                        <InformationCard
                                            subHeading="No actions"
                                            title="None available"
                                        />
                                    )}
                                </div>
                            </>
                        )}
                        {outputs && (
                            <>
                                <Typography
                                    variant="h5"
                                    color="blue-gray">
                                    Outputs specific to {patient.firstName} {patient.lastName}
                                </Typography>

                                <div className="grid gap-6 md:grid-cols-2 xl:grid-cols-4">
                                    {outputs.map(
                                        ({icon, subHeading, title, cardButtonActionTitle, cardButtonParam}) => (
                                            <InformationCard
                                                icon={icon}
                                                subHeading={subHeading}
                                                title={title}
                                                cardButtonActionTitle={cardButtonActionTitle}
                                                cardButtonAction={handleCardButtonAction}
                                                cardButtonParam={cardButtonParam}
                                            />
                                        ),
                                    )}
                                    {outputs.length === 0 && (
                                        <InformationCard
                                            subHeading="No outputs"
                                            title="None available"
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}
        </>
    );
}
