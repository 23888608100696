import React from "react";

// @material-tailwind/react
import {Input, Typography} from "@material-tailwind/react";

export default function CarerBasicDetail({carer}) {
    return (
        <>
            {carer && (
                <>
                    <Typography
                        variant="h5"
                        color="blue-gray">
                        Basic Information
                    </Typography>
                    <Typography
                        variant="small"
                        className="text-gray-600 font-normal mt-1">
                        Overview of carer&apos;s basic information.
                    </Typography>
                    <div className="flex flex-col mt-8">
                        <div className="mb-6 flex flex-col items-end gap-4 md:flex-row">
                            <div className="w-full">
                                <Input
                                    value={carer.firstName}
                                    size="lg"
                                    label="First Name"
                                    className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                />
                            </div>
                            <div className="w-full">
                                <Input
                                    value={carer.lastName}
                                    size="lg"
                                    label="Last Name"
                                    className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                />
                            </div>
                        </div>
                        <div className="mb-6 flex flex-col items-end gap-4 md:flex-row">
                            <div className=" w-full">
                                <Input
                                    value={carer.contactNumber}
                                    size="lg"
                                    label="Contact Number"
                                    className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                />
                            </div>
                            <div className=" w-full">
                                <Input
                                    value={carer.email}
                                    size="lg"
                                    label="Email address"
                                    className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
