import {httpsCallable} from "firebase/functions";
import {functions} from "../config/firebase.config";

export const createPatient = async (data) => {
    try {
        // Call the Firebase cloud function and await the response
        const callable = httpsCallable(functions, "createPatient");
        const response = await callable(data);
        // Return the response
        return response.data;
    } catch (error) {
        console.error(error);
        // Handle failures by returning a custom error
        throw new Error("Failed to create patient");
    }
};

export const updatePatient = async (data) => {
    try {
        // Call the Firebase cloud function and await the response
        const response = await functions.httpsCallable("updatePatient")(data);
        // Return the response
        return response.data;
    } catch (error) {
        // Handle failures by returning a custom error
        throw new Error("Failed to update patient");
    }
};

export const getAllUnassignedPatients = async (data) => {
    try {
        const allPatients = await getAllPatients(data);
        const filtered = allPatients.response.filter((patient) => !patient.hasOwnProperty("carerID"));
        return filtered;
    } catch (error) {
        console.error(error);
        throw new Error("Failed to get all unassigned patients");
    }
};

export const getAllPatients = async (data) => {
    try {
        // Call the Firebase cloud function and await the response
        const callable = httpsCallable(functions, "getAllPatients");
        const response = await callable(data);
        console.log(response);
        return response.data;
    } catch (error) {
        console.error(error);
        // Handle failures by returning a custom error
        throw new Error("Failed to get all patients");
    }
};

export const getPatient = async (data) => {
    try {
        // Call the Firebase cloud function and await the response
        const callable = httpsCallable(functions, "getPatient");
        const response = await callable(data);
        return response.data;
    } catch (error) {
        console.error(error);
        // Handle failures by returning a custom error
        throw new Error("Failed to get patient");
    }
};

export const getPatientInterests = async (data) => {
    try {
        // Call the Firebase cloud function and await the response
        const callable = httpsCallable(functions, "getPatientInterests");
        const response = await callable(data);
        return response.data;
    } catch (error) {
        console.error(error);
        // Handle failures by returning a custom error
        throw new Error("Failed to get patient");
    }
};

export const addPatientInterest = async (data) => {
    try {
        // Call the Firebase cloud function and await the response
        const callable = httpsCallable(functions, "addPatientInterest");
        const response = await callable(data);
        return response.data;
    } catch (error) {
        console.error(error);
        // Handle failures by returning a custom error
        throw new Error("Failed to add patient interest");
    }
};

export const getPatientGoals = async (data) => {
    try {
        // Call the Firebase cloud function and await the response
        const callable = httpsCallable(functions, "getPatientGoals");
        const response = await callable(data);
        return response.data;
    } catch (error) {
        console.error(error);
        // Handle failures by returning a custom error
        throw new Error("Failed to get patient goals");
    }
};

export const addPatientGoal = async (data) => {
    try {
        // Call the Firebase cloud function and await the response
        const callable = httpsCallable(functions, "addPatientGoal");
        const response = await callable(data);
        return response.data;
    } catch (error) {
        console.error(error);
        // Handle failures by returning a custom error
        throw new Error("Failed to add patient goal");
    }
};

export const getPatientInterventions = async (data) => {
    try {
        // Call the Firebase cloud function and await the response
        const callable = httpsCallable(functions, "getPatientInterventions");
        const response = await callable(data);
        return response.data;
    } catch (error) {
        console.error(error);
        // Handle failures by returning a custom error
        throw new Error("Failed to get patient interventions");
    }
};

export const addPatientIntervention = async (data) => {
    try {
        // Call the Firebase cloud function and await the response
        const callable = httpsCallable(functions, "addPatientIntervention");
        const response = await callable(data);
        return response.data;
    } catch (error) {
        console.error(error);
        // Handle failures by returning a custom error
        throw new Error("Failed to add patient interventions");
    }
};
