import React from "react";
import {Card, CardBody, CardFooter, Typography} from "@material-tailwind/react";
import {
    UserPlusIcon,
    UserGroupIcon,
    ClipboardDocumentCheckIcon,
    ClipboardDocumentListIcon,
} from "@heroicons/react/24/solid";

function WidgetsCard({color, icon, title, price, value, label}) {
    return (
        <Card
            className="border !border-blue-gray-100 p-2"
            shadow={false}>
            <CardBody className="!p-4 flex justify-between">
                <div>
                    <Typography
                        color="blue-gray"
                        variant="h5"
                        className="!font-bold mb-2">
                        {title}
                    </Typography>
                    <Typography
                        variant="h4"
                        color="blue-gray"
                        className="!font-bold">
                        {price}
                    </Typography>
                </div>
                {icon}
            </CardBody>
            <CardFooter className="flex items-center gap-2 !p-4">
                <Typography
                    variant="small"
                    className={`!font-bold ${color}`}>
                    {value}
                </Typography>
                <Typography
                    variant="small"
                    className="!font-normal text-gray-500">
                    {label}
                </Typography>
            </CardFooter>
        </Card>
    );
}

const widgets = [
    {
        icon: <UserPlusIcon className="!w-8 !h-8 text-gray-900" />,
        title: "Carers",
        price: "230,300",
        value: "+3%",
        label: "than last month",
        color: "!text-green-500",
    },
    {
        icon: <UserGroupIcon className="!w-8 !h-8 text-gray-900" />,
        title: "Patients",
        price: "3,200",
        value: "+12%",
        label: "than last month",
        color: "!text-green-500",
    },
    {
        icon: <ClipboardDocumentCheckIcon className="!w-8 !h-8 text-gray-900" />,
        title: "Completed Activities",
        price: "1,200",
        value: "+213%",
        label: "than last month",
        color: "!text-green-500",
    },
    {
        icon: <ClipboardDocumentListIcon className="!w-8 !h-8 text-gray-900" />,
        title: "Planned Activities",
        price: "1,388",
        value: "+11%",
        label: "than last month",
        color: "!text-green-500",
    },
];

export default function OrganisationalDashboardComponent() {
    return (
        <section className=" py-12">
            <div className="grid gap-6 md:grid-cols-2 xl:grid-cols-4">
                {widgets.map(({color, icon, title, value, price, label}) => (
                    <WidgetsCard
                        key={title}
                        title={title}
                        value={value}
                        color={color}
                        icon={icon}
                        price={price}
                        label={label}
                    />
                ))}
            </div>
        </section>
    );
}
