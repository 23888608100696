import React, {useEffect, useMemo, useState} from "react";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {Button, Card, CardBody, CardFooter, CardHeader, Typography} from "@material-tailwind/react";
import {useNavigate} from "react-router-dom";

export default function InterestsTable({interests, handleOpenAddInterestForm}) {
    const [rowSelection, setRowSelection] = useState({});
    const [selectedInterest, setSelectedInterest] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        setSelectedInterest(interests[Object.keys(rowSelection)[0]]);
    }, [interests, rowSelection]);

    const columns = useMemo(
        () => [
            {
                accessorKey: "categoryTitle",
                header: "Category",
            },
            {
                accessorKey: "title",
                header: "Title",
            },
            {
                accessorKey: "detail",
                header: "Detail",
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data: Array.isArray(interests) ? interests : [],
        enableRowSelection: true,
        enableMultiRowSelection: false,
        onRowSelectionChange: setRowSelection,
        state: {rowSelection},
    });

    const onClickAddInterest = async () => {
        await handleOpenAddInterestForm();
    };

    const seeDetailsEnabled = () => {
        return selectedInterest !== undefined;
    };

    const onClickSeeInterestDetails = async () => {
        navigate(`${selectedInterest.interestID}`);
    };

    return (
        <>
            <section>
                <Card className="h-full w-full">
                    <CardHeader
                        floated={false}
                        shadow={false}
                        className="rounded-none flex flex-wrap gap-4 justify-between mb-4 p-2">
                        <div>
                            <Typography
                                variant="h6"
                                color="blue-gray">
                                Interests List
                            </Typography>
                            <Typography
                                variant="small"
                                className="text-gray-600 font-normal">
                                See information about all of the patient's interests
                            </Typography>
                        </div>
                        <div className="flex flex-wrap items-center w-full shrink-0 gap-4 md:w-max">
                            <Button
                                color="blue"
                                className="md:max-w-fit w-full"
                                onClick={() => onClickAddInterest()}>
                                add a new interest
                            </Button>
                        </div>
                    </CardHeader>
                    <CardBody className="overflow-scroll !p-0">
                        <MaterialReactTable table={table} />
                    </CardBody>
                    <CardFooter className="rounded-none flex flex-wrap gap-4 justify-between mb-4 ps-8">
                        <div>
                            <Typography
                                variant="para"
                                color="blue-gray">
                                {selectedInterest && `Selected Interest: ${selectedInterest.title}`}
                            </Typography>
                        </div>
                        <div className="flex flex-wrap items-center w-full shrink-0 gap-4 md:w-max">
                            <Button
                                disabled={!seeDetailsEnabled()}
                                seeDetailsEnabled
                                className="md:max-w-fit w-full"
                                onClick={() => onClickSeeInterestDetails()}>
                                See Details
                            </Button>
                        </div>
                    </CardFooter>
                </Card>
            </section>
        </>
    );
}
