import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    IconButton,
    Typography,
    DialogFooter,
    Textarea,
    Input,
    Select,
    Option,
} from "@material-tailwind/react";

import {useAuth} from "../providers/AuthProvider";
import {addPatientActivityRequest} from "../services/activitiesService";

export default function AddArticleModal({open, handleOpen, patient}) {
    const {user} = useAuth();
    const [selectedCategory, setSelectedCategory] = useState();
    const [title, setTitle] = useState();
    const [description, setDescription] = useState();

    const INTERESTS_CATEGORIES = [
        {categoryID: 1, title: "Music"},
        {categoryID: 2, title: "Sports"},
        {categoryID: 3, title: "Fashion"},
        {categoryID: 4, title: "Food"},
        {categoryID: 5, title: "Entertainment"},
        {categoryID: 6, title: "Travel"},
        {categoryID: 7, title: "Technology"},
        {categoryID: 8, title: "Art"},
        {categoryID: 9, title: "Culture"},
        {categoryID: 10, title: "Innovation"},
        {categoryID: 11, title: "War"},
        {categoryID: 12, title: "Exploration"},
        {categoryID: 13, title: "Society"},
        {categoryID: 14, title: "Lifestyle"},
        {categoryID: 15, title: "Politics"},
        {categoryID: 16, title: "Economy"},
        {categoryID: 17, title: "Science"},
        {categoryID: 18, title: "Religion"},
        {categoryID: 19, title: "Events"},
        {categoryID: 20, title: "People"},
        {categoryID: 21, title: "Daily Life"},
        {categoryID: 22, title: "Transportation"},
        {categoryID: 23, title: "Communication"},
        {categoryID: 24, title: "Nature"},
        {categoryID: 25, title: "Traditions"},
    ];

    const onAddActivity = async () => {
        const selectedCategoryObj = INTERESTS_CATEGORIES.find((category) => category.categoryID === selectedCategory);

        const data = {
            organisationID: user.organisationID,
            patientID: patient.patientID,
            categoryID: selectedCategoryObj.categoryID,
            categoryTitle: selectedCategoryObj.title,
            title: title,
            description: description,
        };

        addPatientActivityRequest(data)
            .then((response) => {
                console.info(response);
                setTitle("");
                setSelectedCategory(null);
                setDescription("");
                handleOpen();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <section className="grid place-items-center h-screen">
            <Dialog
                className="p-4"
                open={open}
                handler={handleOpen}>
                <DialogHeader className="justify-between pb-0">
                    <Typography
                        color="blue-gray"
                        variant="h3"
                        className="mb-1 font-bold">
                        Request Activity
                    </Typography>
                    <IconButton
                        color="gray"
                        size="sm"
                        variant="text"
                        onClick={handleOpen}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                            className="h-4 w-4">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </IconButton>
                </DialogHeader>
                <DialogBody className="overflow-y-scroll pt-0">
                    <Typography
                        variant="small"
                        className="font-normal text-gray-600">
                        Fill in the details of the activity you would like to request.
                    </Typography>
                    <div className="mt-4 space-y-4">
                        <Typography
                            color="blue-gray"
                            className="mb-1 font-bold">
                            Category
                        </Typography>
                        <Typography
                            variant="small"
                            className="font-normal text-gray-600 mb-2">
                            Choose a suitable category for the activity:
                        </Typography>
                        <div className="flex flex-wrap gap-x-2">
                            <div className="w-full">
                                <Select
                                    label="Category"
                                    value={selectedCategory}
                                    onChange={(val) => setSelectedCategory(val)}
                                    className=" aria-[expanded=true]:border-t-primary">
                                    {INTERESTS_CATEGORIES.map((category) => (
                                        <Option value={category.categoryID}>{`${category.title}`}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                    <hr className="my-7" />
                    <div className="mt-4 space-y-4">
                        <Typography
                            color="blue-gray"
                            className="mb-1 font-bold">
                            Activity Request Details
                        </Typography>

                        <Input
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            color="gray"
                            label="Title of requested activity"
                            className="w-full placeholder:opacity-100"
                        />

                        <div className="flex flex-wrap gap-x-2">
                            <div className="w-full">
                                <Textarea
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    variant="outlined"
                                    label="Detail of requested activity"
                                />
                            </div>
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter className="gap-2">
                    <Button
                        onClick={() => handleOpen()}
                        variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={() => onAddActivity()}>Submit Request</Button>
                </DialogFooter>
            </Dialog>
        </section>
    );
}
