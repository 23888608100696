import {Route, Routes} from "react-router-dom";
import DashboardView from "../features/app/dashboard/DashboardView";
import Sidebar from "../components/Sidebar";
import PatientsView from "../features/app/patients/PatientsView";
import {useAuth} from "../providers/AuthProvider";
import CarersView from "../features/app/carers/CarersView";
import PatientView from "../features/app/patients/PatientView";
import PatientAssignCarerView from "../features/app/patients/PatientAssignCarerView";
import CarerView from "../features/app/carers/CarerView";
import PatientInterestsView from "../features/app/patients/PatientInterestsView";
import PatientGoalsView from "../features/app/patients/PatientGoalsView";
import PatientInterventionsView from "../features/app/patients/PatientInterventionsView";
import PatientGeneratedContentView from "../features/app/patients/PatientGeneratedContentView";
import PatientActivitiesView from "../features/app/patients/PatientActivitiesView";
import PatientArticlesView from "../features/app/patients/PatientArticlesView";
import PatientQuizzesView from "../features/app/patients/PatientQuizzesView";

export default function PrivateRoutes() {
    const {logOut} = useAuth();

    return (
        <>
            <div className="flex">
                <div className="w-72 h-screen">
                    <Sidebar logoutUser={logOut} />
                </div>
                <div className="flex-1 h-screen p-8">
                    <Routes>
                        <Route
                            path="dashboard"
                            element={<DashboardView />}
                        />
                        <Route
                            path="patients"
                            element={<PatientsView />}
                        />
                        <Route
                            path="patients/:patientID"
                            element={<PatientView />}
                        />
                        <Route
                            path="patients/:patientID/interests"
                            element={<PatientInterestsView />}
                        />
                        <Route
                            path="patients/:patientID/goals"
                            element={<PatientGoalsView />}
                        />
                        <Route
                            path="patients/:patientID/interventions"
                            element={<PatientInterventionsView />}
                        />
                        <Route
                            path="patients/:patientID/carer"
                            element={<PatientAssignCarerView />}
                        />
                        <Route
                            path="patients/:patientID/generated-content"
                            element={<PatientGeneratedContentView />}
                        />
                        <Route
                            path="patients/:patientID/activities"
                            element={<PatientActivitiesView />}
                        />
                        <Route
                            path="patients/:patientID/articles"
                            element={<PatientArticlesView />}
                        />
                        <Route
                            path="patients/:patientID/quizzes"
                            element={<PatientQuizzesView />}
                        />
                        <Route
                            path="carers"
                            element={<CarersView />}
                        />
                        <Route
                            path="carers/:carerID"
                            element={<CarerView />}
                        />
                        <Route
                            path="carers/:carerID/:patientID"
                            element={<PatientView />}
                        />
                    </Routes>
                </div>
            </div>
        </>
    );
}
