import "./assets/styles/App.css";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import PublicRoutes from "./routes/PublicRoutes";
import {AuthProvider} from "./providers/AuthProvider";
import PrivateRoute from "./providers/PrivateRoute";
import PrivateRoutes from "./routes/PrivateRoutes";

function App() {
    return (
        <div className="App">
            <div className="flex flex-col">
                <Router>
                    <AuthProvider>
                        <Routes>
                            <Route
                                path="/member/*"
                                element={
                                    <PrivateRoute>
                                        <PrivateRoutes />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="*"
                                element={<PublicRoutes />}
                            />
                        </Routes>
                    </AuthProvider>
                </Router>
            </div>
        </div>
    );
}

export default App;
