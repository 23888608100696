import React, {useEffect, useMemo, useState} from "react";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {Button, Card, CardBody, CardFooter, CardHeader, Typography} from "@material-tailwind/react";
import {useNavigate} from "react-router-dom";

export default function InterventionsTable({interventions, handleOpenAddInterventionForm}) {
    const [rowSelection, setRowSelection] = useState({});
    const [selectedIntervention, setSelectedIntervention] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        setSelectedIntervention(interventions[Object.keys(rowSelection)[0]]);
    }, [interventions, rowSelection]);

    const columns = useMemo(
        () => [
            {
                accessorKey: "categoryTitle",
                header: "Category",
            },
            {
                accessorKey: "title",
                header: "Title",
            },
            {
                accessorKey: "detail",
                header: "Detail",
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data: Array.isArray(interventions) ? interventions : [],
        enableRowSelection: true,
        enableMultiRowSelection: false,
        onRowSelectionChange: setRowSelection,
        state: {rowSelection},
    });

    const onClickAddIntervention = async () => {
        await handleOpenAddInterventionForm();
    };

    const seeDetailsEnabled = () => {
        return selectedIntervention !== undefined;
    };

    const onClickSeeInterventionDetails = async () => {
        navigate(`${selectedIntervention.interventionID}`);
    };

    return (
        <>
            <section>
                <Card className="h-full w-full">
                    <CardHeader
                        floated={false}
                        shadow={false}
                        className="rounded-none flex flex-wrap gap-4 justify-between mb-4 p-2">
                        <div>
                            <Typography
                                variant="h6"
                                color="blue-gray">
                                Interventions List
                            </Typography>
                            <Typography
                                variant="small"
                                className="text-gray-600 font-normal">
                                See information about all of the patient's interventions
                            </Typography>
                        </div>
                        <div className="flex flex-wrap items-center w-full shrink-0 gap-4 md:w-max">
                            <Button
                                color="blue"
                                className="md:max-w-fit w-full"
                                onClick={() => onClickAddIntervention()}>
                                add a new intervention
                            </Button>
                        </div>
                    </CardHeader>
                    <CardBody className="overflow-scroll !p-0">
                        <MaterialReactTable table={table} />
                    </CardBody>
                    <CardFooter className="rounded-none flex flex-wrap gap-4 justify-between mb-4 ps-8">
                        <div>
                            <Typography
                                variant="para"
                                color="blue-gray">
                                {selectedIntervention && `Selected Intervention: ${selectedIntervention.title}`}
                            </Typography>
                        </div>
                        <div className="flex flex-wrap items-center w-full shrink-0 gap-4 md:w-max">
                            <Button
                                disabled={!seeDetailsEnabled()}
                                seeDetailsEnabled
                                className="md:max-w-fit w-full"
                                onClick={() => onClickSeeInterventionDetails()}>
                                See Details
                            </Button>
                        </div>
                    </CardFooter>
                </Card>
            </section>
        </>
    );
}
