import React, {useEffect, useState} from "react";
import {getAllCarers} from "../../../services/carerService";
import CarerTable from "../../../components/CarerTable";
import {useAuth} from "../../../providers/AuthProvider";
import {useNavigate} from "react-router-dom";
import CreateCarerModal from "../../../components/CreateCarerModal";
import {Typography} from "@material-tailwind/react";

export default function CarersView() {
    const [carers, setCarers] = useState([]);
    const {user} = useAuth();
    const navigate = useNavigate();

    const [openCreateCarerForm, setOpenCreateCarerForm] = React.useState(false);

    /**
     * Retrieves the list of carers when the component mounts and the modal is not visible.
     */
    useEffect(() => {
        if (openCreateCarerForm === false) {
            getCarers({organisationID: user.organisationID})
                .then((response) => {
                    setCarers(response);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [openCreateCarerForm, user.organisationID]);

    /**
     * Handles the opening and closing of the add carer form.
     * @async
     * @function handleOpenAddCarerForm
     * @returns {Promise<void>}
     */
    const handleOpenAddCarerForm = async () => {
        setOpenCreateCarerForm((cur) => !cur);
    };

    /**
     * Retrieves a list of carers based on the provided data.
     *
     * @param {Object} data - The data used to retrieve the carers.
     * @returns {Promise<Array>} A promise that resolves to an array of carers.
     * @throws {Error} If there was an error retrieving the carers.
     */
    const getCarers = (data) => {
        return new Promise((resolve, reject) => {
            getAllCarers(data)
                .then((response) => {
                    if (response.success === false) {
                        reject(response.error);
                        return;
                    }
                    resolve(response.response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    /**
     * Handles the action click event.
     *
     * @param {string} action - The action to be performed.
     * @param {object} row - The row object containing the index.
     */
    const onHandleActionClicked = (action, row) => {
        console.info(action);
        const carer = carers[row.index];
        navigate(`${carer.userID}/${action}`);
    };

    return (
        <>
            <div className="p-4 space-y-4">
                <Typography
                    variant="h1"
                    color="blue-gray">
                    Carers
                </Typography>
                <CarerTable
                    carers={carers}
                    handleActionClick={onHandleActionClicked}
                    handleOpenAddCarerForm={handleOpenAddCarerForm}
                />
                <CreateCarerModal
                    open={openCreateCarerForm}
                    handleOpen={handleOpenAddCarerForm}
                />
            </div>
        </>
    );
}
