import {
    Card,
    Typography,
    List,
    ListItem,
    ListItemPrefix,
    ListItemSuffix,
    Chip,
    MenuItem,
} from "@material-tailwind/react";
import {
    PresentationChartBarIcon,
    UserCircleIcon,
    Cog6ToothIcon,
    InboxIcon,
    CalendarDaysIcon,
    UsersIcon,
} from "@heroicons/react/24/solid";
import {Link, useNavigate} from "react-router-dom";

export default function Sidebar({logoutUser}) {
    const navigate = useNavigate();

    const onClickLogout = async () => {
        await logoutUser();
        navigate("/signup", {replace: true});
    };

    return (
        <Card className="h-[calc(100vh-2rem)] w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5">
            <div className="mb-2 p-4">
                <Typography
                    variant="h5"
                    color="blue-gray">
                    Coordinated Living
                </Typography>
            </div>
            <List>
                <Link to="dashboard">
                    <ListItem>
                        <ListItemPrefix>
                            <PresentationChartBarIcon className="h-5 w-5" />
                        </ListItemPrefix>
                        Dashboard
                    </ListItem>
                </Link>
                <Link to="carers">
                    <ListItem>
                        <ListItemPrefix>
                            <UsersIcon className="h-5 w-5" />
                        </ListItemPrefix>
                        Carers
                    </ListItem>
                </Link>
                <Link to="patients">
                    <ListItem>
                        <ListItemPrefix>
                            <UsersIcon className="h-5 w-5" />
                        </ListItemPrefix>
                        Patients
                    </ListItem>
                </Link>
                <Link to="calendar">
                    <ListItem>
                        <ListItemPrefix>
                            <CalendarDaysIcon className="h-5 w-5" />
                        </ListItemPrefix>
                        Calendar
                    </ListItem>
                </Link>
                <Link to="inbox">
                    <ListItem>
                        <ListItemPrefix>
                            <InboxIcon className="h-5 w-5" />
                        </ListItemPrefix>
                        Inbox
                        <ListItemSuffix>
                            <Chip
                                value="14"
                                size="sm"
                                variant="ghost"
                                color="blue-gray"
                                className="rounded-full"
                            />
                        </ListItemSuffix>
                    </ListItem>
                </Link>
                <Link to="profile">
                    <ListItem>
                        <ListItemPrefix>
                            <UserCircleIcon className="h-5 w-5" />
                        </ListItemPrefix>
                        Profile
                    </ListItem>
                </Link>
                <Link to="settings">
                    <ListItem>
                        <ListItemPrefix>
                            <Cog6ToothIcon className="h-5 w-5" />
                        </ListItemPrefix>
                        Settings
                    </ListItem>
                </Link>

                <MenuItem
                    className=" min-w-full"
                    onClick={() => onClickLogout()}>
                    <Typography
                        variant="button"
                        className="uppercase text-center"
                        color="blue-gray">
                        Log Out
                    </Typography>
                </MenuItem>
            </List>
        </Card>
    );
}
