import React, {useEffect, useMemo, useState} from "react";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {Button, Card, CardBody, CardFooter, CardHeader, Typography} from "@material-tailwind/react";
import {useNavigate} from "react-router-dom";

export default function CarerTable({carers, handleOpenAddCarerForm}) {
    const [rowSelection, setRowSelection] = useState({});
    const [selectedCarer, setSelectedCarer] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        setSelectedCarer(carers[Object.keys(rowSelection)[0]]);
    }, [carers, rowSelection]);

    const columns = useMemo(
        () => [
            {
                accessorKey: "firstName",
                header: "First Name",
            },
            {
                accessorKey: "lastName",
                header: "Last Name",
            },
            {
                accessorKey: "role",
                header: "Role",
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data: Array.isArray(carers) ? carers : [],
        enableRowSelection: true,
        enableMultiRowSelection: false,
        onRowSelectionChange: setRowSelection,
        state: {rowSelection},
    });

    const onClickAddCarer = async () => {
        await handleOpenAddCarerForm();
    };

    const seeDetailsEnabled = () => {
        return selectedCarer !== undefined;
    };

    const onClickSeeCarerDetails = async () => {
        navigate(`${selectedCarer.userID}`);
    };

    return (
        <>
            <section>
                <Card className="h-full w-full">
                    <CardHeader
                        floated={false}
                        shadow={false}
                        className="rounded-none flex flex-wrap gap-4 justify-between mb-4 p-2">
                        <div>
                            <Typography
                                variant="h6"
                                color="blue-gray">
                                Carers List
                            </Typography>
                            <Typography
                                variant="small"
                                className="text-gray-600 font-normal">
                                See information about all Carers
                            </Typography>
                        </div>
                        <div className="flex flex-wrap items-center w-full shrink-0 gap-4 md:w-max">
                            <Button
                                color="blue"
                                className="md:max-w-fit w-full"
                                onClick={() => onClickAddCarer()}>
                                add a new carer
                            </Button>
                        </div>
                    </CardHeader>
                    <CardBody className="overflow-scroll !p-0">
                        <MaterialReactTable table={table} />
                    </CardBody>
                    <CardFooter className="rounded-none flex flex-wrap gap-4 justify-between mb-4 ps-8">
                        <div>
                            <Typography
                                variant="para"
                                color="blue-gray">
                                {selectedCarer &&
                                    `Selected Carer: ${selectedCarer.firstName} ${selectedCarer.lastName}`}
                            </Typography>
                        </div>
                        <div className="flex flex-wrap items-center w-full shrink-0 gap-4 md:w-max">
                            <Button
                                disabled={!seeDetailsEnabled()}
                                seeDetailsEnabled
                                className="md:max-w-fit w-full"
                                onClick={() => onClickSeeCarerDetails()}>
                                See Details
                            </Button>
                        </div>
                    </CardFooter>
                </Card>
            </section>
        </>
    );
}
