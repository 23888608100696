import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useAuth} from "../../../providers/AuthProvider";
import {Typography} from "@material-tailwind/react";
import {getPatientQuizzes} from "../../../services/quizService";
import QuizzesTable from "../../../components/QuizzesTable";
import AddQuizModal from "../../../components/AddQuizModal";

export default function PatientQuizzesView() {
    const {user} = useAuth();
    const location = useLocation();
    const [quizzes, setQuizzes] = useState([]);
    const [openCreateForm, setOpenCreateForm] = React.useState(false);

    const handleOpenAddForm = async () => {
        setOpenCreateForm((cur) => !cur);
    };

    useEffect(() => {
        if (location.state.patient) {
            const data = {organisationID: user.organisationID, patientID: location.state.patient.patientID};
            getPatientQuizzes(data)
                .then((response) => {
                    setQuizzes(response.response);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [location.state.patient, user.organisationID]);

    return (
        <>
            <div className="p-4 space-y-4">
                {location.state.patient && (
                    <Typography
                        variant="h1"
                        color="blue-gray">
                        {location.state.patient.firstName} {location.state.patient.lastName}
                    </Typography>
                )}

                <QuizzesTable
                    patient={location.state.patient}
                    quizzes={quizzes ? quizzes : []}
                    handleOpenAddForm={handleOpenAddForm}
                />

                {location.state.patient && (
                    <AddQuizModal
                        open={openCreateForm}
                        handleOpen={handleOpenAddForm}
                        patient={location.state.patient}
                    />
                )}
            </div>
        </>
    );
}
