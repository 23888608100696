import {Route, Routes} from "react-router-dom";
import HomeView from "../features/home/HomeView";
import HelpView from "../features/help/HelpView";
import SignUpView from "../features/auth/SignUpView";
import LoginView from "../features/auth/LoginView";
import UnauthenticatedHeader from "../components/UnauthenticatedHeader";

export default function PublicRoutes() {
    return (
        <>
            <UnauthenticatedHeader />
            <Routes>
                <Route
                    path="help"
                    element={<HelpView />}
                />
                <Route
                    path="signup"
                    element={<SignUpView />}
                />
                <Route
                    path="login"
                    element={<LoginView />}
                />
                <Route
                    path="/"
                    element={<HomeView />}
                />
            </Routes>
        </>
    );
}
