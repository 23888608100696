import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import CarerBasicDetail from "../../../components/CarerBasicDetail";
import {getCarer, getCarerPatients} from "../../../services/carerService";
import {useAuth} from "../../../providers/AuthProvider";
import {Typography} from "@material-tailwind/react";
import PatientTable from "../../../components/PatientTable";
import InformationCard from "../../../components/InformationCard";
import AssignCarer from "../../../components/AssignCarer";

export default function CarerView() {
    const params = useParams();
    const navigate = useNavigate();
    const {user} = useAuth();
    const [carerID, setCarerID] = useState(null);
    const [carer, setCarer] = useState(null);
    const [informationPoints, setInformationPoints] = useState([]);
    const [carersPatients, setCarersPatients] = useState([]);
    const [loggedInUser, setLoggedInUser] = useState(null);

    useEffect(() => {
        console.info(user);
        setLoggedInUser(user);
    }, [user]);

    useEffect(() => {
        setCarerID(params.carerID);
    }, [params.carerID]);

    useEffect(() => {
        const data = {organisationID: user.organisationID, carerID: carerID};

        getCarer(data)
            .then((response) => {
                setCarer(response.response);
                return getCarerPatients(data);
            })
            .then((response) => {
                setCarersPatients(response.response);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [carerID, user.organisationID]);

    useEffect(() => {
        setInformationPoints([
            {
                subHeading: "Carer Type",
                title: carer ? carerRoleTypeText(carer.role) : "Unknown",
            },
            {
                subHeading: "Availability",
                title: carer ? carerAvailabilityText(carer.availability) : "Unknown",
            },
        ]);
    }, [carer]);

    const carerAvailabilityText = (availability) => {
        let availableDays = [];
        for (const day in availability) {
            if (availability[day]) {
                availableDays.push(day.charAt(0).toUpperCase() + day.slice(1));
            }
        }
        availableDays.sort((a, b) => {
            const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
            return daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b);
        });
        return availableDays.join(", ");
    };

    const carerRoleTypeText = (role) => {
        switch (role) {
            case "aged_care_worker_residential":
                return "Aged Care Practitioner";
            case "aged_care_worker_attendant":
                return "Aged Care Practitioner";
            case "disability_worker_attendant":
                return "Disability Care Practitioner";
            case "personal_care_worker_attendant":
                return "Personal Care Practitioner";
            default:
                return "Unknown";
        }
    };

    const handleCardButtonAction = (action) => {
        switch (action) {
            case "viewPatients":
                navigateToViewPatients();
                break;
            default:
                break;
        }
    };

    const navigateToViewPatients = () => {
        navigate("patients", {
            state: {
                carer: carer,
            },
        });
    };

    const carersPatientsColumns = useMemo(
        () => [
            {
                accessorKey: "patientID",
                header: "First Name",
            },
            {
                accessorKey: "patientName",
                header: "Patient Name",
            },
        ],
        [],
    );

    return (
        <>
            {carer && (
                <div className="p-4">
                    <Typography
                        variant="h1"
                        color="blue-gray">
                        {carer.firstName} {carer.lastName}
                    </Typography>
                    <CarerBasicDetail carer={carer} />
                    {informationPoints && (
                        <>
                            <Typography
                                variant="h5"
                                color="blue-gray">
                                Data
                            </Typography>
                            <Typography
                                variant="small"
                                className="text-gray-600 font-normal mt-1 pb-4">
                                Information points for the patient
                            </Typography>
                            <div className="grid gap-6 md:grid-cols-2 xl:grid-cols-4">
                                {informationPoints.map(
                                    ({icon, subHeading, title, cardButtonActionTitle, cardButtonParam}) => (
                                        <InformationCard
                                            icon={icon}
                                            subHeading={subHeading}
                                            title={title}
                                            cardButtonActionTitle={cardButtonActionTitle}
                                            cardButtonAction={handleCardButtonAction}
                                            cardButtonParam={cardButtonParam}
                                        />
                                    ),
                                )}
                            </div>
                        </>
                    )}

                    {loggedInUser && loggedInUser.role === "organisation" && (
                        <>
                            <div className="pt-4">
                                <Typography
                                    variant="h5"
                                    color="blue-gray">
                                    Organisation Actions
                                </Typography>
                                <Typography
                                    variant="small"
                                    className="text-gray-600 font-normal mt-1 pb-4">
                                    Organisational level actions on the carer
                                </Typography>
                                <AssignCarer
                                    carer={carer}
                                    setCarersPatients={setCarersPatients}
                                />
                            </div>
                        </>
                    )}

                    {carersPatients && (
                        <div className="pt-4">
                            <Typography
                                variant="h5"
                                color="blue-gray">
                                Patients
                            </Typography>
                            <Typography
                                variant="small"
                                className="text-gray-600 font-normal mt-1 pb-4">
                                Patients associated with the carer
                            </Typography>
                            <PatientTable
                                patients={carersPatients}
                                columns={carersPatientsColumns}
                            />
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
