import {httpsCallable} from "firebase/functions";
import {functions} from "../config/firebase.config";

export const getPatientActivities = async (data) => {
    try {
        // Call the Firebase cloud function and await the response
        const callable = httpsCallable(functions, "getPatientActivities");
        const response = await callable(data);
        return response.data;
    } catch (error) {
        console.error(error);
        // Handle failures by returning a custom error
        throw new Error("Failed to get patient activities");
    }
};

export const addPatientActivityRequest = async (data) => {
    try {
        // Call the Firebase cloud function and await the response
        const callable = httpsCallable(functions, "addPatientActivityRequest");
        const response = await callable(data);
        return response.data;
    } catch (error) {
        console.error(error);
        // Handle failures by returning a custom error
        throw new Error("Failed to add patient activity request");
    }
};
