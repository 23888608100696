import React from "react";
import {Navbar, Collapse, Typography, Button, IconButton} from "@material-tailwind/react";
import {Bars3Icon, XMarkIcon} from "@heroicons/react/24/outline";
import {useNavigate} from "react-router-dom";

function NavItem({label, url}) {
    return (
        <Typography
            as="a"
            href={url}
            variant="small"
            color="white"
            className="flex items-center gap-1.5 p-1 font-normal">
            {label}
        </Typography>
    );
}

function NavList() {
    return (
        <ul className="mb-4 mt-2 flex flex-col gap-3 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-8">
            <NavItem
                label="Home"
                url={"/"}
            />
            <NavItem
                label="Help"
                url={"/help"}
            />
        </ul>
    );
}

export default function UnauthenticatedHeader() {
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen((cur) => !cur);
    const navigate = useNavigate();

    React.useEffect(() => {
        window.addEventListener("resize", () => window.innerWidth >= 960 && setOpen(false));
    }, []);

    const onClickLogin = async () => {
        navigate("/login");
    };

    return (
        <Navbar
            color="gray"
            className="bg-gray-900"
            fullWidth>
            <div className="container mx-auto flex items-center justify-between">
                <Typography
                    as="a"
                    href="/"
                    variant="h6"
                    color="white"
                    className="mr-4 cursor-pointer">
                    Coordinated Living
                </Typography>
                <div className="hidden lg:block">
                    <NavList />
                </div>
                <Button
                    onClick={() => {
                        onClickLogin();
                    }}
                    color="white"
                    size="sm"
                    className="hidden lg:inline-block">
                    Log In
                </Button>
                <IconButton
                    size="sm"
                    variant="text"
                    color="white"
                    onClick={handleOpen}
                    className="ml-auto inline-block lg:hidden">
                    {open ? (
                        <XMarkIcon
                            className="h-6 w-6"
                            strokeWidth={2}
                        />
                    ) : (
                        <Bars3Icon
                            className="h-6 w-6"
                            strokeWidth={2}
                        />
                    )}
                </IconButton>
            </div>
            <Collapse open={open}>
                <NavList />
                <Button
                    onClick={() => onClickLogin()}
                    color="white"
                    size="sm"
                    fullWidth
                    className="mb-2">
                    Log In{" "}
                </Button>
            </Collapse>
        </Navbar>
    );
}
