import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    IconButton,
    Typography,
    Input,
    DialogFooter,
    Select,
    Option,
} from "@material-tailwind/react";
import {createPatient} from "../services/patientService";
import {useAuth} from "../providers/AuthProvider";

import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export default function CreatePatientModal({open, handleOpen}) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [typeOfCare, setTypeOfCare] = useState("");
    const [line1, setLine1] = useState("");
    const [line2, setLine2] = useState("");
    const [suburb, setSuburb] = useState("");
    const [postcode, setPostcode] = useState("");
    const [state, setState] = useState("");
    const {user} = useAuth();

    /**
     * Handles the click event for creating a patient.
     * @async
     * @function onClickCreatePatient
     * @returns {Promise<void>}
     */
    const onClickCreatePatient = async () => {
        const patient = {
            organisationID: user.organisationID,
            dateStarted: dayjs.utc().toString(),
            firstName: firstName,
            lastName: lastName,
            contactNumber: contactNumber,
            typeOfCare: typeOfCare,
        };

        patient.care_address = {
            line1: line1,
            line2: line2,
            suburb: suburb,
            postcode: postcode,
            state: state,
        };

        const data = {
            organisationID: user.organisationID,
            patient: patient,
        };

        console.info(data);

        createPatient(data)
            .then((response) => {
                console.info(response);
                onClickHandleOpen();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    /**
     * Handles the click event when opening the modal.
     */
    const onClickHandleOpen = () => {
        setFirstName("");
        setLastName("");
        setContactNumber("");
        setTypeOfCare("");
        handleOpen();
    };

    return (
        <section className="grid place-items-center h-screen">
            <Dialog
                className="p-4"
                open={open}
                handler={onClickHandleOpen}>
                <DialogHeader className="justify-between pb-0">
                    <Typography
                        color="blue-gray"
                        className="mb-1 font-bold">
                        Create Patient
                    </Typography>
                    <IconButton
                        color="gray"
                        size="sm"
                        variant="text"
                        onClick={handleOpen}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                            className="h-4 w-4">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </IconButton>
                </DialogHeader>
                <DialogBody className="overflow-y-scroll pt-0">
                    <Typography
                        variant="small"
                        className="font-normal text-gray-600">
                        Add the patient information below.
                    </Typography>
                    <div className="flex items-center flex-col md:flex-row gap-x-2 gap-y-4 mt-6">
                        <div className="w-full">
                            <Input
                                label="First Name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                color="gray"
                                className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                            />
                        </div>
                        <div className="w-full">
                            <Input
                                label="Last Name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                color="gray"
                                className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                            />
                        </div>
                    </div>
                    <div className="flex items-center flex-col md:flex-row gap-x-2 gap-y-4 mt-6">
                        <div className="w-full">
                            <Input
                                value={contactNumber}
                                onChange={(e) => setContactNumber(e.target.value)}
                                color="gray"
                                label="Contact Number"
                                className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                            />
                        </div>
                    </div>
                    <hr className="my-7" />
                    <div>
                        <Typography
                            color="blue-gray"
                            className="mb-1 font-bold">
                            Carer Type
                        </Typography>
                        <Typography
                            variant="small"
                            className="font-normal text-gray-600 mb-2">
                            What type of care will they receive:
                        </Typography>
                        <div className="flex flex-wrap gap-x-2">
                            <div className="w-full">
                                <Select
                                    value={typeOfCare}
                                    onChange={(val) => setTypeOfCare(val)}
                                    className="border-t-blue-gray-200 aria-[expanded=true]:border-t-primary">
                                    <Option value="aged_care_residential">Aged Care (Residential)</Option>
                                    <Option value="aged_care_attendant">Aged Care (Attendant)</Option>
                                    <Option value="disability_care_attendant">Disability Care (Attendant)</Option>
                                    <Option value="personal_care_attendant">Personal Care (Attendant)</Option>
                                </Select>
                            </div>
                        </div>
                    </div>
                    {typeOfCare && (
                        <>
                            <hr className="my-7" />
                            <div>
                                <Typography
                                    color="blue-gray"
                                    className="mb-1 font-bold">
                                    {typeOfCare !== "aged_care_residential"
                                        ? "Care Address"
                                        : "Residential Care Home Address"}
                                </Typography>
                                <Typography
                                    variant="small"
                                    className="font-normal text-gray-600 mb-2">
                                    The address where the patient will receive the care:
                                </Typography>
                                <div className="flex items-center flex-col md:flex-row gap-x-2 gap-y-4 mt-6">
                                    <div className="w-full">
                                        <Input
                                            label="Line 1"
                                            value={line1}
                                            onChange={(e) => setLine1(e.target.value)}
                                            color="gray"
                                            className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Input
                                            label="Line 2"
                                            value={line2}
                                            onChange={(e) => setLine2(e.target.value)}
                                            color="gray"
                                            className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                        />
                                    </div>
                                </div>
                                <div className="flex items-center flex-col md:flex-row gap-x-2 gap-y-4 mt-6">
                                    <div className="w-full">
                                        <Input
                                            label="Suburb"
                                            value={suburb}
                                            onChange={(e) => setSuburb(e.target.value)}
                                            color="gray"
                                            className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Input
                                            label="Postcode"
                                            value={postcode}
                                            onChange={(e) => setPostcode(e.target.value)}
                                            color="gray"
                                            className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                        />
                                    </div>
                                </div>
                                <div className="flex items-center flex-col md:flex-row gap-x-2 gap-y-4 mt-6">
                                    <div className="w-full">
                                        <Select
                                            label="State"
                                            value={state}
                                            onChange={(val) => setState(val)}
                                            className="border-t-blue-gray-200 aria-[expanded=true]:border-t-primary">
                                            <Option value="tasmania">Tasmania</Option>
                                            <Option value="queensland">Queensland</Option>
                                            <Option value="new_south_wales">New South Wales</Option>
                                            <Option value="victoria">Victoria</Option>
                                            <Option value="western_australia">Western Australia</Option>
                                            <Option value="south_australia">South Australia</Option>
                                            <Option value="northern_territory">Northern Territory</Option>
                                            <Option value="act">Australian Capial Territory</Option>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    <hr className="my-7" />
                </DialogBody>
                <DialogFooter className="gap-2">
                    <Button
                        onClick={() => handleOpen()}
                        variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={() => onClickCreatePatient()}>Create patient</Button>
                </DialogFooter>
            </Dialog>
        </section>
    );
}
