import React from "react";
import DashboardComponent from "../../../components/DashboardComponent";
import NewFeaturesWidget from "../../../components/NewFeaturesWidget";

export default function DashboardView() {
    return (
        <>
            <NewFeaturesWidget />
            <DashboardComponent />
        </>
    );
}
