import React, {useEffect, useMemo, useState} from "react";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {Button, Card, CardBody, CardFooter, CardHeader, Typography} from "@material-tailwind/react";
import {useNavigate} from "react-router-dom";

export default function ActivitiesTable({patient, activities, handleOpenAddForm}) {
    const [rowSelection, setRowSelection] = useState({});
    const [selectedActivity, setSelectedActivity] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        setSelectedActivity(activities[Object.keys(rowSelection)[0]]);
    }, [activities, rowSelection]);

    const columns = useMemo(
        () => [
            {
                accessorKey: "activityID",
                header: "ID",
            },
            {
                accessorKey: "categoryTitle",
                header: "Category",
            },
            {
                accessorKey: "title",
                header: "Title",
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data: Array.isArray(activities) ? activities : [],
        enableRowSelection: true,
        enableMultiRowSelection: false,
        onRowSelectionChange: setRowSelection,
        state: {rowSelection},
    });

    const seeDetailsEnabled = () => {
        return selectedActivity !== undefined;
    };

    const onClickRequestActivity = async () => {
        await handleOpenAddForm();
    };

    const handleOnClickSeeActivityDetails = async () => {
        navigate(`${selectedActivity.activityID}`);
    };

    return (
        <>
            <section>
                <Card className="h-full w-full">
                    <CardHeader
                        floated={false}
                        shadow={false}
                        className="rounded-none flex flex-wrap gap-4 justify-between mb-4 p-2">
                        <div>
                            <Typography
                                variant="h6"
                                color="blue-gray">
                                Activities List
                            </Typography>
                            <Typography
                                variant="small"
                                className="text-gray-600 font-normal">
                                See information about all {patient.firstName} {patient.lastName}'s activities.
                            </Typography>
                        </div>
                        {handleOpenAddForm && (
                            <div className="flex flex-wrap items-center w-full shrink-0 gap-4 md:w-max">
                                <Button
                                    color="blue"
                                    className="md:max-w-fit w-full"
                                    onClick={() => onClickRequestActivity()}>
                                    request a new activity
                                </Button>
                            </div>
                        )}
                    </CardHeader>
                    <CardBody className="overflow-scroll !p-0">
                        <MaterialReactTable table={table} />
                    </CardBody>
                    <CardFooter className="rounded-none flex flex-wrap gap-4 justify-between mb-4 ps-8">
                        <div>
                            <Typography
                                variant="para"
                                color="blue-gray">
                                {selectedActivity && `Selected Activity: ${selectedActivity.title}`}
                            </Typography>
                        </div>
                        <div className="flex flex-wrap items-center w-full shrink-0 gap-4 md:w-max">
                            <Button
                                disabled={!seeDetailsEnabled()}
                                seeDetailsEnabled
                                className="md:max-w-fit w-full"
                                onClick={() => handleOnClickSeeActivityDetails()}>
                                See Details
                            </Button>
                        </div>
                    </CardFooter>
                </Card>
            </section>
        </>
    );
}
