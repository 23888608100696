import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    IconButton,
    Typography,
    Input,
    Checkbox,
    DialogFooter,
    Select,
    Option,
} from "@material-tailwind/react";
import {createCarer} from "../services/carerService";
import {useAuth} from "../providers/AuthProvider";

import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export default function CreateCarerModal({open, handleOpen}) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");
    const [monday, setMonday] = useState(false);
    const [tuesday, setTuesday] = useState(false);
    const [wednesday, setWednesday] = useState(false);
    const [thursday, setThursday] = useState(false);
    const [friday, setFriday] = useState(false);
    const [saturday, setSaturday] = useState(false);
    const [sunday, setSunday] = useState(false);

    const {user} = useAuth();

    /**
     * Handles the click event for creating a carer.
     * @async
     * @function onClickCreateCarer
     * @returns {Promise<void>}
     */
    const onClickCreateCarer = async () => {
        const carer = {
            dateStarted: dayjs.utc().toString(),
            email: email,
            firstName: firstName,
            lastName: lastName,
            mobileNumber: mobileNumber,
            role: role,
            availability: {
                monday: monday,
                tuesday: tuesday,
                wednesday: wednesday,
                thursday: thursday,
                friday: friday,
                saturday: saturday,
                sunday: sunday,
            },
        };

        const data = {
            organisationID: user.organisationID,
            user: carer,
        };

        console.info(data);

        createCarer(data)
            .then((response) => {
                console.info(response);
                setFirstName("");
                setLastName("");
                setMobileNumber("");
                setEmail("");
                setRole("");
                setMonday(false);
                setTuesday(false);
                setWednesday(false);
                setThursday(false);
                setFriday(false);
                setSaturday(false);
                setSunday(false);
                handleOpen();
            })

            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <section className="grid place-items-center h-screen">
            <Dialog
                className="p-4"
                open={open}
                handler={handleOpen}>
                <DialogHeader className="justify-between pb-0">
                    <Typography
                        color="blue-gray"
                        className="mb-1 font-bold">
                        Create Carer
                    </Typography>
                    <IconButton
                        color="gray"
                        size="sm"
                        variant="text"
                        onClick={handleOpen}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                            className="h-4 w-4">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </IconButton>
                </DialogHeader>
                <DialogBody className="overflow-y-scroll pt-0">
                    <Typography
                        variant="small"
                        className="font-normal text-gray-600">
                        Add the carer information below.
                    </Typography>
                    <div className="flex items-center flex-col md:flex-row gap-x-2 gap-y-4 mt-6">
                        <div className="w-full">
                            <Input
                                label="First Name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                color="gray"
                                className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                            />
                        </div>
                        <div className="w-full">
                            <Input
                                label="Last Name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                color="gray"
                                className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                            />
                        </div>
                    </div>
                    <div className="flex items-center flex-col md:flex-row gap-x-2 gap-y-4 mt-6">
                        <div className="w-full">
                            <Input
                                value={mobileNumber}
                                onChange={(e) => setMobileNumber(e.target.value)}
                                color="gray"
                                label="Mobile Number"
                                className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                            />
                        </div>
                        <div className="w-full">
                            <Input
                                label="Email Address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                color="gray"
                                className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                            />
                        </div>
                    </div>
                    <hr className="my-7" />
                    <div>
                        <Typography
                            color="blue-gray"
                            className="mb-1 font-bold">
                            Carer Type
                        </Typography>
                        <Typography
                            variant="small"
                            className="font-normal text-gray-600 mb-2">
                            What type of carer are they employed as:
                        </Typography>
                        <div className="flex flex-wrap gap-x-2">
                            <div className="w-full">
                                <Select
                                    value={role}
                                    onChange={(val) => setRole(val)}
                                    className="border-t-blue-gray-200 aria-[expanded=true]:border-t-primary">
                                    <Option value="aged_care_worker_residential">Aged Care Worker (Residential)</Option>
                                    <Option value="aged_care_worker_attendant">Aged Care Worker (Attendant)</Option>
                                    <Option value="disability_worker_attendant">Disability Worker (Attendant)</Option>
                                    <Option value="personal_care_worker_attendant">Personal Worker (Attendant)</Option>
                                </Select>
                            </div>
                        </div>
                    </div>
                    <hr className="my-7" />
                    <div>
                        <Typography
                            color="blue-gray"
                            className="mb-1 font-bold">
                            Availability
                        </Typography>
                        <Typography
                            variant="small"
                            className="font-normal text-gray-600 mb-2">
                            Choose the days the carer is available to work on
                        </Typography>
                        <div className="flex flex-wrap gap-x-2">
                            <Checkbox
                                checked={monday}
                                onChange={() => setMonday(!monday)}
                                label={<Typography className="font-medium text-gray-600">Mon</Typography>}
                            />
                            <Checkbox
                                checked={tuesday}
                                onChange={() => setTuesday(!tuesday)}
                                label={<Typography className="font-medium text-gray-600">Tue</Typography>}
                            />
                            <Checkbox
                                checked={wednesday}
                                onChange={() => setWednesday(!wednesday)}
                                label={<Typography className="font-medium text-gray-600">Wed</Typography>}
                            />
                            <Checkbox
                                checked={thursday}
                                onChange={() => setThursday(!thursday)}
                                label={<Typography className="font-medium text-gray-600">Thu</Typography>}
                            />
                            <Checkbox
                                checked={friday}
                                onChange={() => setFriday(!friday)}
                                label={<Typography className="font-medium text-gray-600">Fri</Typography>}
                            />
                            <Checkbox
                                checked={saturday}
                                onChange={() => setSaturday(!saturday)}
                                label={<Typography className="font-medium text-gray-600">Sat</Typography>}
                            />
                            <Checkbox
                                checked={sunday}
                                onChange={() => setSunday(!sunday)}
                                label={<Typography className="font-medium text-gray-600">Sun</Typography>}
                            />
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter className="gap-2">
                    <Button
                        onClick={() => handleOpen()}
                        variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={() => onClickCreateCarer()}>Create carer</Button>
                </DialogFooter>
            </Dialog>
        </section>
    );
}
