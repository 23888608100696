import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import React, {useEffect, useMemo, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Button, Card, CardBody, CardFooter, CardHeader, Typography} from "@material-tailwind/react";
import {useAuth} from "../../../providers/AuthProvider";
import {makePatientCarerAssignment} from "../../../services/assignmentService";
import BreadcrumbHeading from "../../../components/BreadcrumbHeading";

export default function PatientAssignCarerView() {
    const {user} = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [rowSelection, setRowSelection] = useState({});
    const [selectedCarer, setSelectedCarer] = useState({});

    useEffect(() => {
        if (location.state.patient.carerID) {
            const existingCarer = location.state.carers.find(
                (carer) => carer.userID === location.state.patient.carerID,
            );
            if (existingCarer) setSelectedCarer(existingCarer);
        }
    }, [location.state.patient, location.state.carers]);

    useEffect(() => {
        setSelectedCarer(location.state.carers[Object.keys(rowSelection)[0]]);
    }, [location.state.carers, rowSelection]);

    const columns = useMemo(
        () => [
            {
                accessorKey: "userID",
                header: "ID",
            },
            {
                accessorKey: "email",
                header: "Carer Email",
            },
            {
                accessorKey: "firstName",
                header: "First Name",
            },
            {
                accessorKey: "lastName",
                header: "Last Name",
            },
            {
                accessorKey: "mobileNumber",
                header: "Mobile Number",
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data: Array.isArray(location.state.carers) ? location.state.carers : [],
        enableRowSelection: true,
        enableMultiRowSelection: false,
        onRowSelectionChange: setRowSelection,
        state: {rowSelection},
    });

    const assignCarerEnabled = () => {
        return selectedCarer !== undefined;
    };

    const onClickAssignCarer = () => {
        const data = {
            organisationID: user.organisationID,
            patientID: location.state.patient.patientID,
            patientName: `${location.state.patient.firstName} ${location.state.patient.lastName}`,
            carerID: selectedCarer.userID,
            carerName: `${selectedCarer.firstName} ${selectedCarer.lastName}`,
        };
        makePatientCarerAssignment(data)
            .then((response) => {
                console.info(response);
                setSelectedCarer(null);
            })
            .then(() => {
                navigate(-1);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <>
            {location.state.contents && <BreadcrumbHeading contents={location.state.contents} />}
            <section>
                <Card className="h-full w-full">
                    <CardHeader
                        floated={false}
                        shadow={false}
                        className="rounded-none flex flex-wrap gap-4 justify-between mb-4 p-2">
                        <div>
                            <Typography
                                variant="h6"
                                color="blue-gray">
                                Carers List
                            </Typography>
                            <Typography
                                variant="small"
                                className="text-gray-600 font-normal">
                                Select a carer to assign to {location.state.patient.firstName}{" "}
                                {location.state.patient.lastName}
                            </Typography>
                        </div>
                    </CardHeader>
                    <CardBody className="overflow-scroll !p-0">
                        <MaterialReactTable table={table} />
                    </CardBody>
                    <CardFooter className="rounded-none flex flex-wrap gap-4 justify-between mb-4 ps-8">
                        <div>
                            <Typography
                                variant="para"
                                color="blue-gray">
                                {selectedCarer &&
                                    `Selected Patient: ${selectedCarer.firstName} ${selectedCarer.lastName}`}
                            </Typography>
                        </div>
                        <div className="flex flex-wrap items-center w-full shrink-0 gap-4 md:w-max">
                            <Button
                                disabled={!assignCarerEnabled()}
                                seeDetailsEnabled
                                className="md:max-w-fit w-full"
                                onClick={() => onClickAssignCarer()}>
                                Assign Carer
                            </Button>
                        </div>
                    </CardFooter>
                </Card>
            </section>
        </>
    );
}
