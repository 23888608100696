import {httpsCallable} from "firebase/functions";
import {functions} from "../config/firebase.config";

export const createCarer = async (data) => {
    try {
        // Call the Firebase cloud function and await the response
        const callable = httpsCallable(functions, "createCarer");
        const response = await callable(data);
        return response.data;
    } catch (error) {
        console.error(error);
        // Handle failures by returning a custom error
        throw new Error("Failed to create carer");
    }
};
export const getAllCarers = async (data) => {
    try {
        // Call the Firebase cloud function and await the response
        const callable = httpsCallable(functions, "getAllCarers");
        const response = await callable(data);
        console.log(response);
        return response.data;
    } catch (error) {
        console.error(error);
        // Handle failures by returning a custom error
        throw new Error("Failed to get all carers");
    }
};

export const getCarer = async (data) => {
    try {
        // Call the Firebase cloud function and await the response
        const callable = httpsCallable(functions, "getCarer");
        const response = await callable(data);
        return response.data;
    } catch (error) {
        console.error(error);
        // Handle failures by returning a custom error
        throw new Error("Failed to get carer");
    }
};

export const getCarerPatients = async (data) => {
    try {
        // Call the Firebase cloud function and await the response
        const callable = httpsCallable(functions, "getCarerPatients");
        const response = await callable(data);
        return response.data;
    } catch (error) {
        console.error(error);
        // Handle failures by returning a custom error
        throw new Error("Failed to get carer patients");
    }
};
