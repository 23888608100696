import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useAuth} from "../../../providers/AuthProvider";
import {Typography} from "@material-tailwind/react";
import {getPatientArticles} from "../../../services/articlesService";
import ArticlesTable from "../../../components/ArticlesTable";
import AddArticleModal from "../../../components/AddArticleModal";

export default function PatientArticlesView() {
    const {user} = useAuth();
    const location = useLocation();
    const [articles, setArticles] = useState([]);
    const [openCreateForm, setOpenCreateForm] = React.useState(false);

    const handleOpenAddForm = async () => {
        setOpenCreateForm((cur) => !cur);
    };

    useEffect(() => {
        if (location.state.patient) {
            console.info("PatientID: ", location.state.patient.patientID);
            const data = {organisationID: user.organisationID, patientID: location.state.patient.patientID};
            getPatientArticles(data)
                .then((response) => {
                    console.info(response);
                    setArticles(response);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [location.state.patient, user.organisationID]);

    return (
        <>
            <div className="p-4 space-y-4">
                {location.state.patient && (
                    <Typography
                        variant="h1"
                        color="blue-gray">
                        {location.state.patient.firstName} {location.state.patient.lastName}
                    </Typography>
                )}
                {articles && (
                    <ArticlesTable
                        patient={location.state.patient}
                        articles={articles}
                        handleOpenAddForm={handleOpenAddForm}
                    />
                )}
                {location.state.patient && (
                    <AddArticleModal
                        open={openCreateForm}
                        handleOpen={handleOpenAddForm}
                        patient={location.state.patient}
                    />
                )}
            </div>
        </>
    );
}
