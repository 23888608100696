import {httpsCallable} from "firebase/functions";
import {functions} from "../config/firebase.config";

export const getPatientQuizzes = async (data) => {
    try {
        // Call the Firebase cloud function and await the response
        const callable = httpsCallable(functions, "getPatientQuizzes");
        const response = await callable(data);
        return response.data;
    } catch (error) {
        console.error(error);
        // Handle failures by returning a custom error
        throw new Error("Failed to get patient quizzes");
    }
};

export const addPatientQuizRequest = async (data) => {
    try {
        // Call the Firebase cloud function and await the response
        const callable = httpsCallable(functions, "addPatientQuizRequest");
        const response = await callable(data);
        return response.data;
    } catch (error) {
        console.error(error);
        // Handle failures by returning a custom error
        throw new Error("Failed to add patient quiz request");
    }
};
