import {Typography, Input, Button} from "@material-tailwind/react";
import holdingHandsImage from "../../assets/img/cover-1.jpg";
import {useState} from "react";
import {useAuth} from "../../providers/AuthProvider";
import validator from "validator";
import {useNavigate} from "react-router-dom";

export default function LoginView() {
    const {loginUser} = useAuth();
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const onClickLogin = async () => {
        if (!password) {
            console.error("You need to add a password. Please try again");
            return;
        }

        if (validator.isEmail(email) === false) {
            console.error("Invalid email. Please try again");
            return;
        }
        console.log("Logging in");
        await loginUser(email, password);
        navigate("/member/dashboard", {replace: true});
    };

    return (
        <section className="grid h-screen items-center lg:grid-cols-2">
            <div className="my-auto p-8 text-center sm:p-10 md:p-20 xl:px-32 xl:py-24">
                <Typography
                    variant="h3"
                    color="blue-gray"
                    className="mb-2">
                    Log In
                </Typography>
                <Typography
                    color="gray"
                    className="mb-12">
                    Enter your email and password to log in
                </Typography>

                <form
                    action="#"
                    className="mx-auto max-w-[24rem] text-left">
                    <div className="mb-8">
                        <Input
                            color="gray"
                            size="lg"
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <Input
                            color="gray"
                            size="lg"
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="flex flex-wrap items-center justify-between gap-2">
                        <div className="-ml-3"></div>
                        <Typography
                            as="a"
                            href="#"
                            color="gray"
                            className="font-medium">
                            Forgot password
                        </Typography>
                    </div>
                    <Button
                        onClick={() => onClickLogin()}
                        color="gray"
                        size="lg"
                        className="mt-6"
                        fullWidth>
                        Log In
                    </Button>
                    <Typography
                        color="gray"
                        className="m-8">
                        Dont have an account? Click here to{" "}
                        <a
                            href="/signup"
                            className="text-blue-500">
                            Sign Up
                        </a>
                    </Typography>
                </form>
            </div>
            <img
                src={holdingHandsImage}
                alt="Holding hands"
                className="hidden h-screen w-full object-cover lg:block"
            />
        </section>
    );
}
