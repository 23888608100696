import React, {useEffect} from "react";
import InterestsTable from "../../../components/InterestsTable";
import {useAuth} from "../../../providers/AuthProvider";
import {useLocation} from "react-router-dom";
import {getPatientInterests} from "../../../services/patientService";
import AddInterestModal from "../../../components/AddInterestModal";
import {Typography} from "@material-tailwind/react";

export default function PatientInterestsView() {
    const {user} = useAuth();
    const location = useLocation();
    const [interests, setInterests] = React.useState([]);
    const [openCreateInterestForm, setOpenCreateInterestForm] = React.useState(false);

    const handleOpenAddInterestForm = async () => {
        setOpenCreateInterestForm((cur) => !cur);
    };

    useEffect(() => {
        if (openCreateInterestForm === false) {
            const patientID = location.state.patient.patientID;
            const data = {organisationID: user.organisationID, patientID: patientID};
            getPatientInterests(data)
                .then((response) => {
                    console.info(response);
                    setInterests(response.response);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [openCreateInterestForm, location.state.patient, user.organisationID]);

    return (
        <>
            <div className="p-4 space-y-4">
                {location.state.patient && (
                    <Typography
                        variant="h1"
                        color="blue-gray">
                        {location.state.patient.firstName} {location.state.patient.lastName}
                    </Typography>
                )}
                {interests && (
                    <InterestsTable
                        interests={interests}
                        handleOpenAddInterestForm={handleOpenAddInterestForm}
                    />
                )}
                {location.state.patient && (
                    <AddInterestModal
                        open={openCreateInterestForm}
                        handleOpen={handleOpenAddInterestForm}
                        patient={location.state.patient}
                    />
                )}
            </div>
        </>
    );
}
