import React from "react";
import {Button, Card, CardBody, CardFooter, Typography} from "@material-tailwind/react";

export default function InformationCard({
    icon,
    subHeading,
    title,
    cardButtonActionTitle,
    cardButtonAction,
    cardButtonParam,
}) {
    return (
        <Card
            className="border !border-blue-gray-100 p-2"
            shadow={false}>
            <CardBody className="!p-4 flex justify-between">
                <div>
                    <Typography
                        color="blue-gray"
                        variant="paragraph"
                        className="mb-2">
                        {subHeading}
                    </Typography>
                    <Typography
                        variant="h4"
                        color="blue-gray"
                        className="!font-bold">
                        {title}
                    </Typography>
                </div>
                {icon}
            </CardBody>
            {cardButtonActionTitle && (
                <CardFooter className="flex items-center gap-2 !p-4">
                    <Button
                        fullWidth
                        onClick={() => cardButtonAction(cardButtonParam)}>
                        {cardButtonActionTitle}
                    </Button>
                </CardFooter>
            )}
        </Card>
    );
}
