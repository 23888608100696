import {Typography} from "@material-tailwind/react";
import {Breadcrumbs} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";

export default function BreadcrumbHeading({contents}) {
    const navigate = useNavigate();

    const onClickBreadcrumb = (breadcrumb) => {
        navigate(breadcrumb.link);
    };
    return (
        <div className="container mx-auto flex items-center justify-between p-4">
            <div>
                {contents && contents.length > 0 && (
                    <Breadcrumbs className="!bg-white">
                        {contents.map((content, index) => {
                            return (
                                // <a
                                //     href={content.link}
                                //     className="opacity-60 font-bold">
                                <Typography
                                    as={"a"}
                                    onClick={(e) => onClickBreadcrumb(content)}
                                    variant="paragraph"
                                    color="blue-gray">
                                    {content.title} {index > 0 && "/"}
                                </Typography>
                                // </a>
                            );
                        })}
                    </Breadcrumbs>
                )}
            </div>
        </div>
    );
}
