import React, {useEffect} from "react";
import InterventionsTable from "../../../components/InterventionsTable";
import {useAuth} from "../../../providers/AuthProvider";
import {useLocation} from "react-router-dom";
import {getPatientInterventions} from "../../../services/patientService";
import {Typography} from "@material-tailwind/react";
import AddInterventionModal from "../../../components/AddInterventionModal";

export default function PatientInterventionsView() {
    const {user} = useAuth();
    const location = useLocation();
    const [interventions, setInterventions] = React.useState([]);
    const [openCreateInterventionForm, setOpenCreateInterventionForm] = React.useState(false);

    const handleOpenAddInterventionForm = async () => {
        setOpenCreateInterventionForm((cur) => !cur);
    };

    useEffect(() => {
        if (openCreateInterventionForm === false) {
            const patientID = location.state.patient.patientID;
            const data = {organisationID: user.organisationID, patientID: patientID};
            getPatientInterventions(data)
                .then((response) => {
                    console.info(response);
                    setInterventions(response.response);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [openCreateInterventionForm, location.state.patient, user.organisationID]);

    return (
        <>
            <div className="p-4 space-y-4">
                {location.state.patient && (
                    <Typography
                        variant="h1"
                        color="blue-gray">
                        {location.state.patient.firstName} {location.state.patient.lastName}
                    </Typography>
                )}
                {interventions && (
                    <InterventionsTable
                        interventions={interventions}
                        handleOpenAddInterventionForm={handleOpenAddInterventionForm}
                    />
                )}
                {location.state.patient && (
                    <AddInterventionModal
                        open={openCreateInterventionForm}
                        handleOpen={handleOpenAddInterventionForm}
                        patient={location.state.patient}
                    />
                )}
            </div>
        </>
    );
}
