import {httpsCallable} from "firebase/functions";
import {functions} from "../config/firebase.config";

export const getPatientArticles = async (data) => {
    try {
        // Call the Firebase cloud function and await the response
        const callable = httpsCallable(functions, "getPatientArticles");
        const response = await callable(data);
        return response.data;
    } catch (error) {
        console.error(error);
        // Handle failures by returning a custom error
        throw new Error("Failed to get patient articles");
    }
};

export const addPatientArticleRequest = async (data) => {
    try {
        // Call the Firebase cloud function and await the response
        const callable = httpsCallable(functions, "addPatientArticleRequest");
        const response = await callable(data);
        return response.data;
    } catch (error) {
        console.error(error);
        // Handle failures by returning a custom error
        throw new Error("Failed to add patient article request");
    }
};
