import React, {useEffect} from "react";
import GoalsTable from "../../../components/GoalsTable";
import {useAuth} from "../../../providers/AuthProvider";
import {useLocation} from "react-router-dom";
import {getPatientGoals} from "../../../services/patientService";
import {Typography} from "@material-tailwind/react";
import AddGoalModal from "../../../components/AddGoalModal";

export default function PatientGoalsView() {
    const {user} = useAuth();
    const location = useLocation();
    const [goals, setGoals] = React.useState([]);
    const [openCreateGoalsForm, setOpenCreateGoalsForm] = React.useState(false);

    const handleOpenAddGoalsForm = async () => {
        setOpenCreateGoalsForm((cur) => !cur);
    };

    useEffect(() => {
        if (openCreateGoalsForm === false) {
            const patientID = location.state.patient.patientID;
            const data = {organisationID: user.organisationID, patientID: patientID};
            getPatientGoals(data)
                .then((response) => {
                    console.info(response);
                    setGoals(response.response);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [openCreateGoalsForm, location.state.patient, user.organisationID]);

    return (
        <>
            <div className="p-4 space-y-4">
                {location.state.patient && (
                    <Typography
                        variant="h1"
                        color="blue-gray">
                        {location.state.patient.firstName} {location.state.patient.lastName}
                    </Typography>
                )}
                {goals && (
                    <GoalsTable
                        goals={goals}
                        handleOpenAddInterestForm={handleOpenAddGoalsForm}
                    />
                )}
                {location.state.patient && (
                    <AddGoalModal
                        open={openCreateGoalsForm}
                        handleOpen={handleOpenAddGoalsForm}
                        patient={location.state.patient}
                    />
                )}
            </div>
        </>
    );
}
