import React, {useEffect, useMemo, useState} from "react";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {Button, Card, CardBody, CardFooter, CardHeader, Typography} from "@material-tailwind/react";
import {useNavigate} from "react-router-dom";

export default function ArticlesTable({patient, articles, handleOpenAddForm}) {
    const [rowSelection, setRowSelection] = useState({});
    const [selectedArticle, setSelectedArticle] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        setSelectedArticle(articles[Object.keys(rowSelection)[0]]);
    }, [articles, rowSelection]);

    const columns = useMemo(
        () => [
            {
                accessorKey: "articleID",
                header: "ID",
            },
            {
                accessorKey: "categoryTitle",
                header: "Category",
            },
            {
                accessorKey: "categoryTitle",
                header: "Category",
            },
            {
                accessorKey: "title",
                header: "Title",
            },
            {
                accessorKey: "created",
                header: "Created",
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data: Array.isArray(articles) ? articles : [],
        enableRowSelection: true,
        enableMultiRowSelection: false,
        onRowSelectionChange: setRowSelection,
        state: {rowSelection},
    });

    const seeDetailsEnabled = () => {
        return selectedArticle !== undefined;
    };

    const onClickRequestArticle = async () => {
        await handleOpenAddForm();
    };

    const handleOnClickSeeArticleDetails = async () => {
        navigate(`${selectedArticle.articleID}`);
    };

    return (
        <>
            <section>
                <Card className="h-full w-full">
                    <CardHeader
                        floated={false}
                        shadow={false}
                        className="rounded-none flex flex-wrap gap-4 justify-between mb-4 p-2">
                        <div>
                            <Typography
                                variant="h6"
                                color="blue-gray">
                                Articles List
                            </Typography>
                            <Typography
                                variant="small"
                                className="text-gray-600 font-normal">
                                See information about all {patient.firstName} {patient.lastName}'s articles.
                            </Typography>
                        </div>
                        {handleOpenAddForm && (
                            <div className="flex flex-wrap items-center w-full shrink-0 gap-4 md:w-max">
                                <Button
                                    color="blue"
                                    className="md:max-w-fit w-full"
                                    onClick={() => onClickRequestArticle()}>
                                    request a new article
                                </Button>
                            </div>
                        )}
                    </CardHeader>
                    <CardBody className="overflow-scroll !p-0">
                        <MaterialReactTable table={table} />
                    </CardBody>
                    <CardFooter className="rounded-none flex flex-wrap gap-4 justify-between mb-4 ps-8">
                        <div>
                            <Typography
                                variant="para"
                                color="blue-gray">
                                {selectedArticle && `Selected Article: ${selectedArticle.title}`}
                            </Typography>
                        </div>
                        <div className="flex flex-wrap items-center w-full shrink-0 gap-4 md:w-max">
                            <Button
                                disabled={!seeDetailsEnabled()}
                                seeDetailsEnabled
                                className="md:max-w-fit w-full"
                                onClick={() => handleOnClickSeeArticleDetails()}>
                                See Details
                            </Button>
                        </div>
                    </CardFooter>
                </Card>
            </section>
        </>
    );
}
