import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    IconButton,
    Typography,
    DialogFooter,
    Textarea,
    Input,
    Select,
    Option,
} from "@material-tailwind/react";

import {useAuth} from "../providers/AuthProvider";
import {addPatientIntervention} from "../services/patientService";

export default function AddInterventionModal({open, handleOpen, patient}) {
    const {user} = useAuth();
    const [selectedCategory, setSelectedCategory] = useState();
    const [title, setTitle] = useState();
    const [detail, setDetail] = useState();

    const categories = [
        {categoryID: 1, title: "Medical Management"},
        {categoryID: 2, title: "Physical Therapy"},
        {categoryID: 3, title: "Nutritional Support"},
        {categoryID: 4, title: "Mental Health Services"},
        {categoryID: 5, title: "Social Support"},
        {categoryID: 6, title: "Personal Care Assistance"},
        {categoryID: 7, title: "Mobility Aids"},
        {categoryID: 8, title: "Safety and Security Measures"},
        {categoryID: 9, title: "Pain Management"},
        {categoryID: 10, title: "End-of-Life Care"},
    ];

    const onAddIntervention = async () => {
        const selectedCategoryObj = categories.find((category) => category.categoryID === selectedCategory);

        const data = {
            organisationID: user.organisationID,
            patientID: patient.patientID,
            categoryID: selectedCategoryObj.categoryID,
            categoryTitle: selectedCategoryObj.title,
            title: title,
            detail: detail,
        };

        addPatientIntervention(data)
            .then((response) => {
                console.info(response);
                setDetail("");
                setTitle("");
                setSelectedCategory(null);
                handleOpen();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <section className="grid place-items-center h-screen">
            <Dialog
                className="p-4"
                open={open}
                handler={handleOpen}>
                <DialogHeader className="justify-between pb-0">
                    <Typography
                        color="blue-gray"
                        variant="h3"
                        className="mb-1 font-bold">
                        Add Intervention
                    </Typography>
                    <IconButton
                        color="gray"
                        size="sm"
                        variant="text"
                        onClick={handleOpen}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                            className="h-4 w-4">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </IconButton>
                </DialogHeader>
                <DialogBody className="overflow-y-scroll pt-0">
                    <Typography
                        variant="small"
                        className="font-normal text-gray-600">
                        Fill in the details of the patient's intervention. This will inform the care plan.
                    </Typography>
                    <div className="mt-4 space-y-4">
                        <Typography
                            color="blue-gray"
                            className="mb-1 font-bold">
                            Category
                        </Typography>
                        <Typography
                            variant="small"
                            className="font-normal text-gray-600 mb-2">
                            Choose a suitable category for the intervention:
                        </Typography>
                        <div className="flex flex-wrap gap-x-2">
                            <div className="w-full">
                                <Select
                                    label="Category"
                                    value={selectedCategory}
                                    onChange={(val) => setSelectedCategory(val)}
                                    className=" aria-[expanded=true]:border-t-primary">
                                    {categories.map((category) => (
                                        <Option value={category.categoryID}>{`${category.title}`}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                    <hr className="my-7" />
                    <div className="mt-4 space-y-4">
                        <Typography
                            color="blue-gray"
                            className="mb-1 font-bold">
                            Intervention Details
                        </Typography>

                        <Input
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            color="gray"
                            label="Title of interest"
                            className="w-full placeholder:opacity-100"
                        />

                        <div className="flex flex-wrap gap-x-2">
                            <div className="w-full">
                                <Textarea
                                    value={detail}
                                    onChange={(e) => setDetail(e.target.value)}
                                    variant="outlined"
                                    label="Detail of interest"
                                />
                            </div>
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter className="gap-2">
                    <Button
                        onClick={() => handleOpen()}
                        variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={() => onAddIntervention()}>Add Intervention</Button>
                </DialogFooter>
            </Dialog>
        </section>
    );
}
